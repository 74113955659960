// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2quZ4KtQQfXgNfk9W-xilj {\n  margin-top: 60px;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n._3VivInGGnuZz2mcQYPjtXs {\n  text-align: center;\n  padding: 30px;\n  padding-bottom: 40px;\n  margin-top: 64px;\n  margin-bottom: 64px;\n  margin-right: 40px;\n}\n\n._3VivInGGnuZz2mcQYPjtXs h2,\n._3VivInGGnuZz2mcQYPjtXs h3 {\n  color: rgb(18, 20, 126);\n}\n\n@media screen and (max-width: 480px) {\n  ._2quZ4KtQQfXgNfk9W-xilj {\n    margin-top: 50px;\n  }\n  ._3VivInGGnuZz2mcQYPjtXs {\n    padding: 10px;\n    margin-bottom: 40px;\n  }\n  ._3VivInGGnuZz2mcQYPjtXs iframe {\n    width: 310px;\n    height: 240px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/contact/Contact.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".ContactContainer {\n  margin-top: 60px;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n.Adress {\n  text-align: center;\n  padding: 30px;\n  padding-bottom: 40px;\n  margin-top: 64px;\n  margin-bottom: 64px;\n  margin-right: 40px;\n}\n\n.Adress h2,\n.Adress h3 {\n  color: rgb(18, 20, 126);\n}\n\n@media screen and (max-width: 480px) {\n  .ContactContainer {\n    margin-top: 50px;\n  }\n  .Adress {\n    padding: 10px;\n    margin-bottom: 40px;\n  }\n  .Adress iframe {\n    width: 310px;\n    height: 240px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContactContainer": "_2quZ4KtQQfXgNfk9W-xilj",
	"Adress": "_3VivInGGnuZz2mcQYPjtXs"
};
export default ___CSS_LOADER_EXPORT___;
