import { createStore, compose } from "redux";
import langReducer from "./redux/reducers/language";

const initialState = {
  language: "uz",
};

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const store = createStore(langReducer, initialState, composeEnhancers());

export default store;
